import { useContext } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import styles from './assets/home.module.scss';
import { Context } from '../../store';

const Index: NextPage = () => {
  const { dispatch } = useContext(Context);
  const { store } = useContext(Context);
  return (
    <div>
      <Head>
        <meta title="home" />
      </Head>
      <p className={styles.home}>/home</p>
      <button onClick={() => dispatch({ type: 'testStore', payload: 'updated' })}>Store dispatch {store?.test}</button>
      <p>
        <Link href="/example">
          <a>Example route</a>
        </Link>
      </p>
    </div>
  );
};

export default Index;
